export default function Form(el, {
	actions,
	events,
	refresh,
}) {
	const error = el.querySelector('p');
	const form = el.querySelector('form');
	const fieldset = form.querySelector('fieldset');
	const nav = form.querySelector('nav');
	const submits = nav.querySelectorAll('[type="submit"]');

	// Enable forms when JS available for recaptcha validation
	submits.forEach(s => s.removeAttribute('disabled'));

	function processForm(e) {
		grecaptcha.ready(() => {
			grecaptcha.execute('6LfzfQckAAAAAJG34x_t4s5X26mHyPeEWqp8BV7F', {
				action: 'submit',
			}).then(token => {
				const body = new FormData(form);
				// Append value of submit buttons that was clicked
				const { name, value } = e.submitter;
				body.append(name, value);
				// Add recaptcha token
				body.append('token', token);

				fetch('/', {
					method: 'POST',
					headers: {
						'Accept': 'application/json',
					},
					body,
				})
					.then(res =>  res.json().then(json => ({
						status: res.status,
						...json,
					})))
					.then(({
						status,
						message = '',
						errors = {},
						next = '',
						download = '',
						redirect = '',
					})=> {
						// Clear errors
						Array.from(body.keys()).forEach(name => {
							events.emit(actions.showFieldError, { name, errors: [] }); });

						if (status === 500) {
							window.alert(message);
							submits.forEach(s => s.removeAttribute('disabled'));

							return;
						}

						if (status === 400) {
							Object.entries(errors).forEach(([name, errs]) => {
								events.emit(actions.showFieldError, { name, errors: errs }); });
							submits.forEach(s => s.removeAttribute('disabled'));

							return;
						}

						if (next) {
							fetch(`/shared/form?${next}`)
								.then(response => response.json())
								.then(({ fields, buttons }) => {
									fieldset.innerHTML = fields;
									nav.innerHTML = buttons;
									refresh(fieldset);
								});

							return;
						}

						events.emit(actions.formSubmitSuccess);
						submits.forEach(s => s.removeAttribute('disabled'));

						if (download) {
							form.download.value = download;
							form.submit();
						}

						if (redirect) {
							window.location = redirect;
						}
					});
			});
		});
	}

	form.onsubmit = e => {
		e.preventDefault();
		submits.forEach(s => s.setAttribute('disabled', 'true'));

		// Dynamically load recaptcha script
		if (window.grecaptcha === undefined) {
			const s = document.createElement('script');

			s.src = 'https://www.google.com/recaptcha/api.js?render=6LfzfQckAAAAAJG34x_t4s5X26mHyPeEWqp8BV7F';
			s.onload = processForm.bind(this, e);

			document.body.appendChild(s);
		} else {
			processForm(e);
		}
	};
}
